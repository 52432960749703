import * as React from "react";

export interface FooterProps {}

export class Footer extends React.Component<FooterProps, {}> {
  render() {
    return (
      <footer id="ft">
        <div className="wrap">&copy; {new Date().getUTCFullYear()} Ni Rui</div>
      </footer>
    );
  }
}
