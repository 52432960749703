import * as React from "react";

import {
  Feed as FeedData,
  Feeds as FeedsData,
  Day as DayData,
  Attachment as AttachmentData,
  AttachmentType,
  Year
} from "../service/feed";

import { Leave } from "./Leave"

export interface MinuteProps {
  feed: FeedData;
}

export class Minute extends React.Component<MinuteProps, {}> {
  time(t: Date) {
    const twoDig = (d: number) => {
      return d >= 10 ? "" + d : "0" + d;
    };

    return twoDig(t.getUTCHours()) + ":" + twoDig(t.getUTCMinutes());
  }

  attachments(feed: FeedData, attachments: Array<AttachmentData>) {
    return (
      <div className="eb2-attachments hfc-attch">
        {attachments.map((att, index) => {
          switch (att.type) {
            case AttachmentType.Image:
              return (
                <div key={index} className="hfc-attch-image">
                  <Leave href={feed.link}>
                    <img src={att.url} alt={feed.title} />
                  </Leave>
                </div>
              );
          }

          return "";
        })}
      </div>
    );
  }

  render() {
    return (
      <li className="eventbox2">
        <span className="eb2-title" suppressHydrationWarning={true}>
          {this.time(new Date(this.props.feed.date))}
        </span>
        <div className="eb2-content home-feed-content">
          {this.props.feed.prefix}{" "}
          <Leave className="hfc-title" href={this.props.feed.link}>
            {this.props.feed.title}
          </Leave>
          {this.props.feed.attachments.length > 0
            ? this.attachments(this.props.feed, this.props.feed.attachments)
            : ""}
        </div>
      </li>
    );
  }
}

export interface DayProps {
  day: DayData;
}

const monthToWord = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export class Day extends React.Component<DayProps, {}> {
  list() {
    return this.props.day.feeds.map((w, index) => (
      <Minute key={index} feed={w} />
    ));
  }

  render() {
    return (
      <li className="eventbox1">
        <h3 className="eb1-title" suppressHydrationWarning={true}>
          {(monthToWord as any)[this.props.day.month]}, {this.props.day.day}
        </h3>

        <ul className="lst-nostyle lst1">{this.list()}</ul>
      </li>
    );
  }
}

export interface FeedProps {
  yeah: Year;
}

export class Feed extends React.Component<FeedProps, {}> {
  list() {
    return this.props.yeah.days.map((w, index) => <Day key={index} day={w} />);
  }

  render() {
    return (
      <section className="home-feed-item">
        <h2>{this.props.yeah.year}</h2>

        <div className="home-feed-list">
          <div className="home-feed-list-content">
            <ul className="lst-nostyle lst1">{this.list()}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export interface FeedsProps {
  feeds: FeedsData;
}

export class Feeds extends React.Component<FeedsProps, {}> {
  render() {
    return this.props.feeds.map((w, index) => <Feed key={index} yeah={w} />);
  }
}
