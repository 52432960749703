import * as React from "react";

export class Base<T, S> extends React.Component<T, S> {
  componentDidMount() {
    window.scrollTo({
      left: 0,
      top: 0
    });
  }
}
