import * as React from "react";

export interface LeaveProps {
  id?: string;
  href: string;
  className?: string;
  seoFollow?: boolean;
}

export const maxLeaveLinkLength = 512;

export function isValid(url: string): boolean {
  if (url.length <= 0 || url.length > maxLeaveLinkLength) {
    return false;
  }
  return url.startsWith("http://") || url.startsWith("https://")
}

export class Leave extends React.Component<LeaveProps, {}> {
  linkClick = (e: any) => {
    let target = e.target;
    while (!target.href) {
      if (!target.parentNode) {
        return;
      }
      target = target.parentNode;
    }
    e.preventDefault();
    window.location.href = "/leave/" + window.encodeURIComponent(target.href);
  };

  linkHover = (e: any) => {
    let target = e.target;
    while (!target.href) {
      if (!target.parentNode) {
        return;
      }
      target = target.parentNode;
    }
    if (target.hoverPinged) {
      return;
    }
    target.hoverPinged = true;
    let req = new window.XMLHttpRequest();
    req.open("GET", "/api/ping/leave/" + window.encodeURIComponent(target.href));
    req.send(null);
  }

  render() {
    if (!isValid(this.props.href) || this.props.seoFollow) {
      return (
        <a
          id={this.props.id}
          className={this.props.className}
          href={this.props.href}
          onMouseEnter={this.linkHover}
        >
          {this.props.children}
        </a>
      );
    }
    return (
      <a
        id={this.props.id}
        className={this.props.className}
        href={this.props.href}
        onClick={this.linkClick}
        onMouseEnter={this.linkHover}
        rel="nofollow"
      >
        {this.props.children}
      </a>
    );
  }
}
