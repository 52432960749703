import * as React from "react";

import { Route, Routes } from "react-router-dom";

import Home from "./Home";
import Projects from "./Projects";
import Contacts from "./Contacts";
import { HTTP404 } from "./Error";

export interface MainProps {}

export class Main extends React.Component<MainProps, {}> {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contacts />} />
        <Route path="*" element={<HTTP404 />} />
      </Routes>
    );
  }
}
