import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { Base } from "./Common";
import { fetch } from "../application/projects";
import { Project as Item } from "../service/project";
import { Action, FetchStatus } from "../application/view";
import { Leave } from "./Leave"

export interface ProjectProps {
  item: Item;
}

export class Project extends React.Component<ProjectProps, {}> {
  render() {
    return (
      <li>
        <div className="project">
          <h2 className="ptitle">
            {this.props.item.homePage.length > 0 ? (
              <Leave href={this.props.item.homePage} seoFollow>
                {this.props.item.title}
              </Leave>
            ) : (
              this.props.item.title
            )}
          </h2>

          <p className="pdescription">{this.props.item.description}</p>

          <div className="pinfo">
            <Leave 
              className="piitem pto" 
              href={this.props.item.linkTo} 
              seoFollow
            >
              {this.props.item.linkTitle}
            </Leave>

            <span className="piitem" suppressHydrationWarning={true}>
              Updated: {new Date(this.props.item.updated).toLocaleString()}
            </span>
          </div>
        </div>
      </li>
    );
  }
}

const ACTION_FETCHED = "PROJECTS:FETCHED";
export function loadedProjects(data: Array<Item>): Action {
  return {
    type: ACTION_FETCHED,
    payload: data
  };
}

const ACTION_FETCHINGFAILED = "PROJECTS:FETCHINGFAILED";
export function loadingProjectsFailed(e: Error): Action {
  return {
    type: ACTION_FETCHINGFAILED,
    payload: e
  };
}

const ACTION_FETCHING = "PROJECTS:FETCHING";
export function loadingProjects(): Action {
  return {
    type: ACTION_FETCHING,
    payload: null
  };
}

export function handler(state: any, action: Action) {
  switch (action.type) {
    case ACTION_FETCHED:
      return {
        ...state,
        projects: {
          projects: action.payload,
          loading: FetchStatus.Fetched
        }
      };

    case ACTION_FETCHING:
      return {
        ...state,
        projects: {
          loading: FetchStatus.Fetching
        }
      };

    case ACTION_FETCHINGFAILED:
      return {
        ...state,
        projects: {
          loading: FetchStatus.Failed
        }
      };

    default:
      return state;
  }
}

export interface ProjectsProps {
  projects: Array<Item> | null;
  loading: FetchStatus;
  loadingProjects: typeof loadingProjects;
  loadedProjects: typeof loadedProjects;
  loadingProjectsFailed: typeof loadingProjectsFailed;
}

export class Projects extends Base<ProjectsProps, {}> {
  list() {
    switch (this.props.loading) {
      case FetchStatus.Fetching:
        return <p>Fetching data from remote server ...</p>;

      case FetchStatus.Failed:
        return <p>Unable to fetch data from remote server</p>;
    }

    if (!this.props.projects) {
      const self = this;

      fetch({
        onLoading() {
          self.props.loadingProjects();
        },
        onCompleted(d) {
          self.props.loadedProjects(d);
        },
        onError(e) {
          self.props.loadingProjectsFailed(e);
        }
      });

      return;
    }

    if (this.props.projects.length <= 0) {
      return <p>No project is currently available</p>;
    }

    return (
      <div className="sp-content">
        <ul id="project-list" className="lst-nostyle hlst2">
          {this.props.projects.map((project, index) => {
            return <Project key={index} item={project} />;
          })}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <main id="project" className="subpage">
        <Helmet>
          <title>Projects</title>
        </Helmet>

        <div className="wrap">
          <h1 className="sp-title">Projects</h1>

          {this.list()}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  if (state && state.projects) {
    return {
      projects: state.projects.projects,
      loading: state.projects.loading
    };
  }

  return {
    projects: null,
    loading: FetchStatus.Fetched
  };
};

const mapDispatchToProps = {
  loadingProjects: loadingProjects,
  loadedProjects: loadedProjects,
  loadingProjectsFailed: loadingProjectsFailed
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects);
