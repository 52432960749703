import * as React from "react";
import { Helmet } from "react-helmet";

import { Context } from "./Context";

export interface ErrorProps {
  statusCode: number;
  title: string;
  description: string;
}

export function Error(props: ErrorProps) {
  const context = React.useContext(Context);
  context.request.statusCode = props.statusCode;
  return (
    <main id="error" className="subpage">
      <Helmet>
        <title>{ props.title }</title>
      </Helmet>
      <div className="wrap">
        <h1 className="sp-title">{ props.title }</h1>
        { props.description }
      </div>
    </main>
  );
}

export class HTTP404 extends React.Component<{}, {}>  {
  render() {
    return (
      <Error
        statusCode={404}
        title="Not found"
        description="Sorry, the resources that you were looking for cannot be found on current system"
      />
    )
  }
}
