import * as React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, Store } from "redux";

import { Action } from "../application/view";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Main } from "./Main";
import { handler as projectsHandler } from "./Projects";
import { handler as contactsHandler } from "./Contacts";
import { handler as homeHandler } from "./Home";

function handler(state: any, action: Action) {
  let prefixIdx = action.type.indexOf(":", 0);

  if (prefixIdx < 0) {
    return state;
  }

  switch (action.type.substr(0, prefixIdx)) {
    case "PROJECTS":
      return projectsHandler(state, action);

    case "CONTACTS":
      return contactsHandler(state, action);

    case "HOME":
      return homeHandler(state, action);

    default:
      return state;
  }
}

export interface ApplicationProps {
  initialState: any;
}

export class Application extends React.Component<ApplicationProps, {}> {
  private handlerStore: Store;

  constructor(props: ApplicationProps) {
    super(props);
    this.handlerStore = createStore(handler, this.props.initialState);
  }

  render() {
    return (
      <>
        <Provider store={this.handlerStore}>
          <Header />
          <Main />
        </Provider>
        <Footer />
      </>
    );
  }
}
