import * as React from "react";
import { NavLink } from "react-router-dom";

export interface LinkProps {
  id?: string;
  to: string;
  class?: string;
}

export class Link extends React.Component<LinkProps, {}> {
  render() {
    return (
      <NavLink
        id={this.props.id}
        className={this.props.class}
        to={this.props.to}
      >
        {this.props.children}
      </NavLink>
    );
  }
}
