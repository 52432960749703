import * as React from "react";
import { Link } from "./Navigation";

export interface NavProps {
  id: string;
}

class Nav extends React.Component<NavProps, {}> {
  render() {
    return (
      <div id={this.props.id}>
        <Link class="" to="/projects">
          <span><span>Projects</span></span>
        </Link>
            <Link class="hlight" to="/contact">
          <span><span>Contact</span></span>
        </Link>
      </div>
    );
  }
}

export interface HeaderProps {}

export class Header extends React.Component<HeaderProps, {}> {
  render() {
    return (
      <header id="hd">
        <div className="wrap">
          <nav>
            <Link id="hd-logo" to="/">Ni Rui's Home Page</Link>
            <Nav id="hd-nav" />
          </nav>
        </div>
      </header>
    );
  }
}
