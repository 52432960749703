import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { fetch } from "../application/feeds";
import { Action, FetchStatus } from "../application/view";
import { Feeds as FeedsData } from "../service/feed";
import { Base } from "./Common";
import { Feeds } from "./Feeds";
import { Link } from "./Navigation";


const ACTION_FEEDS_FETCHED = "HOME:FEEDS_FETCHED";
export function loadedFeeds(data: FeedsData): Action {
  return {
    type: ACTION_FEEDS_FETCHED,
    payload: data
  };
}

const ACTION_FEEDS_FETCHINGFAILED = "HOME:FEEDS_FETCHINGFAILED";
export function loadingFeedsFailed(e: Error): Action {
  return {
    type: ACTION_FEEDS_FETCHINGFAILED,
    payload: e
  };
}

const ACTION_FEEDS_FETCHING = "HOME:FEEDS_FETCHING";
export function loadingFeeds(): Action {
  return {
    type: ACTION_FEEDS_FETCHING,
    payload: null
  };
}

export function handler(state: any, action: Action) {
  switch (action.type) {
    case ACTION_FEEDS_FETCHED:
      return {
        ...state,
        feeds: {
          feeds: action.payload,
          loading: FetchStatus.Fetched
        }
      };

    case ACTION_FEEDS_FETCHING:
      return {
        ...state,
        feeds: {
          loading: FetchStatus.Fetching
        }
      };

    case ACTION_FEEDS_FETCHINGFAILED:
      return {
        ...state,
        feeds: {
          loading: FetchStatus.Failed
        }
      };

    default:
      return state;
  }
}

export interface HomeProps {
  feeds: FeedsData | null;
  feedsLoading: FetchStatus;
  loadingFeeds: typeof loadingFeeds;
  loadedFeeds: typeof loadedFeeds;
  loadingFeedsFailed: typeof loadingFeedsFailed;
}

export class Home extends Base<HomeProps, {}> {
  feeds() {
    switch (this.props.feedsLoading) {
      case FetchStatus.Fetching:
        return <p>Fetching data from remote server ...</p>;

      case FetchStatus.Failed:
        return <p>Unable to fetch data from remote server</p>;
    }

    if (!this.props.feeds) {
      const self = this;

      fetch({
        onLoading() {
          self.props.loadingFeeds();
        },
        onCompleted(d) {
          self.props.loadedFeeds(d);
        },
        onError(e) {
          self.props.loadingFeedsFailed(e);
        }
      });

      return;
    }

    if (this.props.feeds.length <= 0) {
      return <p>No Feed is currently available</p>;
    }

    return <Feeds feeds={this.props.feeds} />;
  }

  render() {
    return (
      <main id="home">
        <Helmet>
          <title>Hey! I'm Rui</title>
        </Helmet>

        <div className="wrap">
          <header id="home-welcome">
            <div id="home-welcome-title">
              <h1>
                I'm <strong>Rui</strong>
              </h1>
              <p>I make stuff with computer code</p>
            </div>
            <div
              id="home-welcome-portrait"
              style={{
                display: "none"
              }}
            >
              <div id="home-welcome-portrait-image"></div>
            </div>
          </header>

          <article id="home-about" className="home-subsection">
            <h1>About me</h1>

            <p>
              My name is Rui. I design and develop computer programs and
              sometimes, staring at the sky for hours.
            </p>

            <p>
              I was born in the late 80s in a small city in the south part of
              China.
            </p>

            <p>
              My biggest interest and also an ongoing attempt is to learn and
              create systems that helps people to find and share truthful and
              valuable information.
            </p>

            <p>
              My other hobbies include photography (done poorly), listening to
              music and above all, writing codes that may or may not be useful.
            </p>
          </article>

          <article id="home-feed" className="home-subsection">
            <h1>Feeds</h1>

            {this.feeds()}
          </article>

          <article id="home-contact" className="home-subsection">
            <h1 id="home-contact-link">
              <Link class="hlight" to="/contact">
                <span><span>Get in touch</span></span>
              </Link>
            </h1>
          </article>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  if (state && state.feeds) {
    return {
      feeds: state.feeds.feeds,
      feedsLoading: state.feeds.loading
    };
  }

  return {
    feeds: null,
    feedsLoading: FetchStatus.Fetched
  };
};

const mapDispatchToProps = {
  loadingFeeds: loadingFeeds,
  loadedFeeds: loadedFeeds,
  loadingFeedsFailed: loadingFeedsFailed
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
