import * as React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { Base } from "./Common";
import { Leave } from "./Leave"
import { Contact as Item } from "../service/contact";
import { fetch } from "../application/contacts";
import { FetchStatus, Action } from "../application/view";

const ACTION_FETCHED = "CONTACTS:FETCHED";
export function loadedContacts(data: Array<Item>): Action {
  return {
    type: ACTION_FETCHED,
    payload: data
  };
}

const ACTION_FETCHINGFAILED = "CONTACTS:FETCHINGFAILED";
export function loadingContactsFailed(e: Error): Action {
  return {
    type: ACTION_FETCHINGFAILED,
    payload: e
  };
}

const ACTION_FETCHING = "CONTACTS:FETCHING";
export function loadingContacts(): Action {
  return {
    type: ACTION_FETCHING,
    payload: null
  };
}

export interface ContactProps {
  contacts: Array<Item> | null;
  loading: FetchStatus;
  loadingContacts: typeof loadingContacts;
  loadedContacts: typeof loadedContacts;
  loadingContactsFailed: typeof loadingContactsFailed;
}

export function handler(state: any, action: Action) {
  switch (action.type) {
    case ACTION_FETCHED:
      return {
        ...state,
        contacts: {
          contacts: action.payload,
          loading: FetchStatus.Fetched
        }
      };

    case ACTION_FETCHING:
      return {
        ...state,
        contacts: {
          loading: FetchStatus.Fetching
        }
      };

    case ACTION_FETCHINGFAILED:
      return {
        ...state,
        contacts: {
          loading: FetchStatus.Failed
        }
      };

    default:
      return state;
  }
}

export class Contact extends React.Component<Item, {}> {
  render() {
    return (
      <li>
        <h2>
          <Leave href={this.props.link} seoFollow>{this.props.title}</Leave>
        </h2>

        <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
      </li>
    );
  }
}

export class Contacts extends Base<ContactProps, {}> {
  list() {
    switch (this.props.loading) {
      case FetchStatus.Fetching:
        return <p>Fetching data from remote server ...</p>;

      case FetchStatus.Failed:
        return <p>Unable to fetch data from remote server</p>;
    }

    if (!this.props.contacts) {
      const self = this;

      fetch({
        onLoading() {
          self.props.loadingContacts();
        },
        onCompleted(d) {
          self.props.loadedContacts(d);
        },
        onError(e) {
          self.props.loadingContactsFailed(e);
        }
      });

      return;
    }

    if (this.props.contacts.length <= 0) {
      return <p>No contact is currently available</p>;
    }

    return (
      <div className="sp-content">
        <p>If needed, you can reach me with following contact methods:</p>

        <ul id="contact-methods" className="lst1">
          {this.props.contacts.map((contact, index) => {
            return (
              <Contact
                key={index}
                title={contact.title}
                link={contact.link}
                description={contact.description}
              />
            );
          })}
        </ul>

        <p>Average response time ranging from 6 to 72 hours.</p>
      </div>
    );
  }

  render() {
    return (
      <main id="contact" className="subpage">
        <Helmet>
          <title>Contact</title>
        </Helmet>

        <div className="wrap">
          <h1 className="sp-title">Establish contact</h1>

          {this.list()}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  if (state && state.contacts) {
    return {
      contacts: state.contacts.contacts,
      loading: state.contacts.loading
    };
  }

  return {
    contacts: null,
    loading: FetchStatus.Fetched
  };
};

const mapDispatchToProps = {
  loadingContacts: loadingContacts,
  loadedContacts: loadedContacts,
  loadingContactsFailed: loadingContactsFailed
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contacts);
